<template>
  <div class="container-md col-lg-11 col-md-11 pb-5 mb-2">
    <div>
      <CRow class="pr-0 pl-0">
        <CCol md="12" sm="12" lg="12">
          <h4 class="text-primary main-title mt-2 mb-4">Email Configuration</h4>
        </CCol>
      </CRow>
    </div>
    <div>
      <CCard>
        <CCardBody>
          <div class="position-absolute tour">
            <CButton
              class="btn-link p-0"
              v-c-tooltip="{
                content: 'Start Tour',
                appendToBody: true,
              }"
              @click="startTour"
            >
              <img src="/img/question.png" alt="Tour" />
            </CButton>
          </div>
          <div>
            <ul class="nav nav-tabs border-0">
              <li class="nav-item">
                <a
                  href="#email-config"
                  target="_self"
                  class="nav-link"
                  :class="{ active: activeTab == 'Email Config' }"
                  @click="toggleTab('Email Config'), orgReset('emailConfig')"
                  >Configuration</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#email-assets"
                  target="_self"
                  class="nav-link"
                  :class="{ active: activeTab == 'Email Assets' }"
                  @click="toggleTab('Email Assets'), orgReset('emailAssets')"
                  >Header/Footer</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#email-templates"
                  target="_self"
                  class="nav-link"
                  :class="{ active: activeTab == 'Email Templates' }"
                  @click="
                    toggleTab('Email Templates'), orgReset('emailTemplates')
                  "
                  >Templates</a
                >
              </li>
            </ul>
          </div>

          <div>
            <div class="tab-content">
              <div
                class="tab-pane"
                :class="{ active: activeTab == 'Email Config' }"
                :style="activeTab == 'Email Config' ? '' : 'display:none;'"
              >
                <EmailConfig ref="emailConfig" />
              </div>
              <div
                class="tab-pane"
                :class="{ active: activeTab == 'Email Assets' }"
                :style="activeTab == 'Email Assets' ? '' : 'display:none;'"
              >
                <EmailAssets ref="emailAssets" />
              </div>
              <div
                class="tab-pane"
                :class="{ active: activeTab == 'Email Templates' }"
                :style="activeTab == 'Email Templates' ? '' : 'display:none;'"
              >
                <EmailTemplate
                  ref="emailTemplates"
                  @templateModalCallBAck="templateModalCallBAck"
                />
              </div>
            </div>
          </div>

          <CRow class="mb-3">
            <div
              class="
                col-lg-12 col-md-12 col-sm-12
                d-flex
                justify-content-center
              "
            >
              <button
                class="
                  col-sm-1
                  btn
                  rounded-0
                  btn-outline-primary btn-lg
                  float-left
                  mt-2
                  ml-2
                "
                @click="navToDashboard()"
              >
                Home
              </button>
              <button
                @click="goBack()"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Back
              </button>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="alertModal.modalTitle"
      :modalColor="alertModal.modalColor"
      :modalContent="alertModal.modalContent"
      :isShowPopup="alertModal.isShowPopup"
      :buttons="alertModal.buttons"
      :modalCallBack="alertModalCallBack"
      :closeOnBackdrop="false"
    />
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="myCallbacks"
      :options="tourOptions"
      :class="{ 'overlay-tour': isShowTour }"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template #header>
              <header class="modal-header p-0 px-1 my-1 py-1">
                <h5 class="d-flex">
                  {{ tour.steps[tour.currentStep].header.title }}
                </h5>
                <button
                  aria-label="Close"
                  class="close btn-link"
                  @click="closeTour()"
                >
                  x
                </button>
              </header>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import Select from "@/components/reusable/Fields/Select.vue";
import EmailConfig from "@/containers/Communication/EmailConfig/EmailConfig.vue";
import EmailTemplate from "@/containers/Communication/EmailConfig/EmailTemplate.vue";
import EmailAssets from "@/containers/Communication/EmailConfig/EmailAssets.vue";
import Modal from "@/components/reusable/Modal";

export default {
  components: {
    Select,
    EmailConfig,
    EmailTemplate,
    EmailAssets,
    Modal,
  },
  data() {
    return {
      activeTab: "Email Config",
      alertModal: {
        modalTitle: "Alert",
        modalColor: "primary",
        modalContent: "Please Cancel or Save the updates to proceed!",
        isShowPopup: false,
        buttons: ["Cancel", "Save"],
      },
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip Tour",
          buttonPrevious: "< Previous",
          buttonNext: "Next >",
          buttonStop: "Finish",
        },
        enabledButtons: {
          buttonSkip: false,
          buttonPrevious: true,
          buttonNext: true,
          buttonStop: true,
        },
      },
      isShowTour: false,
      steps: [
        {
          header: {
            title: "Configuration:",
          },
          content: `<strong><strong>Step 1:</strong></strong> Configure your system generated emails. 
          <ol type="1">
          <li><strong><strong>Organisation:</strong></strong> select your Organisation (applies to Group Organisations with more than one facility with different branding)</li>
          <li><strong><strong>From Email:</strong></strong> Insert the sending email address </li>
          <li><strong><strong>From Name:</strong></strong>  Insert the display name associated to the sending email address</li>
          <li><strong><strong>Reply To:</strong></strong> Insert the receiving email address(es). This is likely to be the same as (3) above</li>
          <li><strong><strong>CC:</strong></strong> Insert email addresses that require to be copied in</li>
          </ol><br>
          <strong><strong>Click Submit to enable the templates </strong></strong>`,
        },
        {
          header: {
            title: "Header/Footer",
          },
          content: `<strong><strong>Step 2:</strong></strong>  Insert the Headers & Footers for each organisation<br>
          <br>
          <strong><strong>Note:</strong></strong> Image size should be 940x400 px and in PNG format only `,
        },
        {
          header: {
            title: "Templates",
          },
          content: `<strong><strong>Step 3:</strong></strong>  Update email body content for each system email  category 
          If prompted, click 'Enable' for first time use. 
          <ol type="1">
          <li><strong><strong>Organisation :</strong></strong> select your Organisation (as per Step 1)</li>
          <li><strong><strong>Category :</strong></strong> select the email to edit the template. Add any required salutation, default content, signatures etc</li>
          </ol>`,
        },
      ],
      myCallbacks: {
        onStart: this.tourCallBack,
        onStop: this.tourStopCallBack,
        onFinish: this.tourCallBack,
        onPreviousStep: this.navPreStepCallback,
        onNextStep: this.navNextStepCallback,
      },
    };
  },
  computed: {
    ...mapGetters(["allCustomerOrganisation", "getEmailConfiguration"]),
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
    isConfigExist() {
      return this.getEmailConfiguration?.acronym ? false : true;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchEmailConfiguration",
      "fetchAllCustomerOrganisation",
    ]),
    closeTour() {
      this.$tours["myTour"].stop();
    },
    navNextStepCallback(currentStep) {
      const activeTab = {
        0: "Email Assets",
        1: "Email Templates",
      };
      if (activeTab[currentStep]) this.activeTab = activeTab[currentStep];
    },
    navPreStepCallback(currentStep) {
      const activeTab = {
        1: "Email Config",
        2: "Email Assets",
        3: "Email Templates",
      };
      if (activeTab[currentStep]) this.activeTab = activeTab[currentStep];
    },
    tourStopCallBack() {
      this.isShowTour = false;
    },
    tourCallBack() {
      this.isShowTour = !this.isShowTour;
    },
    startTour() {
      const activeTab = {
        "Email Config": 0,
        "Email Assets": 1,
        "Email Templates": 2,
      };
      const startStep = activeTab[this.activeTab] || 0;
      this.$tours["myTour"].start(startStep);
    },
    orgReset(name) {
      this.$refs[name].handleChangeOrgSelect(
        "organisation",
        this.allOrganisationListOpt[0]
      );
    },
    templateModalCallBAck(tab) {
      this.activeTab = tab;
    },
    navToDashboard() {
      this.$router.push({
        path: "/recruiter-dashboard",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    toggleTab(tab) {
      let name =
        this.activeTab[0].toLowerCase() +
        this.activeTab.slice(1).replace(/\s/g, "");
      const isField = Object.keys(this.$refs[name].payload);
      if (name == "emailConfig" && isField.length > 0) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Cancel or Save the updates to proceed!",
        });
      } else if (name == "emailAssets" && isField.length > 0) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Cancel or Save the updates to proceed!",
        });
      } else {
        this.activeTab = tab;
      }
    },
    alertModalCallBack(action) {
      let name =
        this.activeTab[0].toLowerCase() +
        this.activeTab.slice(1).replace(/\s/g, "");
      if (action == "Save") {
        this.$refs[name].onSubmit();
      } else {
        this.$refs[name].resetOption();
      }
      this.alertModal.isShowPopup = false;
    },
  },
  mounted() {
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        this.fetchEmailConfiguration(
          this.allOrganisationListOpt[0].path_acronym
        );
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}
.tour {
  top: 5px;
  right: 5px;
}
</style>
