<template>
  <div>
    <CCard>
      <CCardBody class="m-4">
        <CRow class="justify-content-between">
          <CCol md="12" sm="12" lg="4">
            <ValidationObserver ref="uploadform" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)" name="uploadform">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder">
                      Organisation
                    </label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation"
                        :value="orgs.organisation"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        :disabled="checkChildOrg"
                        @input="handleChangeOrgSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-4">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder"
                      >Header/Footer</label
                    >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="assets_type"
                        :value="email_static_assets.assets_type"
                        :options="
                          options && options['email_static_assets']
                            ? options['email_static_assets']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeEmailSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3" v-if="checkFileUpload">
                  <div class="w-100">
                    <div class="d-flex justify-content-center">
                      <button
                        type="button"
                        @click="deleteFile(email_static_assets.assets_type)"
                        class="box-item text-center p-2 m-2 border rounded"
                      >
                        <div class="button-icon mb-2">
                          <i class="fa-solid fa-trash-can"></i>
                        </div>
                        <div class="col-sm-12">
                          <p class="m-0">Delete</p>
                        </div>
                      </button>
                      <button
                        type="button"
                        @click="openCropModal()"
                        class="text-center p-2 m-2 border rounded"
                      >
                        <div class="button-icon mb-2">
                          <i class="fa-solid fa-crop"></i>
                        </div>
                        <div class="col-sm-12">
                          <p class="m-0">Crop</p>
                        </div>
                      </button>
                    </div>
                  </div>
                </CRow>
                <CRow class="row mb-3" v-else>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 mb-3"
                    v-if="checkFileExist"
                  >
                    <code class="text-primary">
                      <span>{{ getFileName }}</span>
                      <span class="nx-90 cursor-pointer">
                        <i
                          class="fas fa-trash-can ml-3"
                          @click="deleteFile(email_static_assets.assets_type)"
                        ></i>
                      </span>
                    </code>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <input
                      id="upload-image"
                      name="upload-image"
                      type="file"
                      ref="fileref"
                      :accept="fileType"
                      @change="
                        handleFile(email_static_assets.assets_type, $event)
                      "
                      :disabled="
                        !orgs.organisation || !email_static_assets.assets_type
                      "
                    />
                    <div class="mt-3">
                      <div class="m-0" style="font-size: 10px">
                        <strong class="text-black">Note :</strong>
                        <ul>
                          <li>Image size should be {{ getPixelSize }} px</li>
                          <li>Extension allowed png type only</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div
                    class="
                      d-flex
                      justify-content-end
                      col-lg-12 col-md-12 col-sm-12
                    "
                  >
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      class="mx-1"
                      @click="resetOption()"
                      :disabled="isSubmitting"
                      >Cancel</CButton
                    >
                    <CButton
                      type="button"
                      color="primary"
                      class="mx-1"
                      @click="onSubmit"
                      :disabled="isSubmitting"
                      >Submit</CButton
                    >
                  </div>
                </CRow>
              </form>
            </ValidationObserver>
          </CCol>
          <CCol md="12" sm="12" lg="7" class="border rounded preview-box">
            <CRow class="row justify-content-center m-0 mb-3 border">
              <div
                class="header w-100"
                :class="{
                  hightlight: isHeader,
                }"
              >
                <img
                  id="preview_img"
                  :src="preview_header"
                  @error="$event.target.src = header_placeholder"
                  class="w-100"
                  alt="header"
                />
              </div>
              <div class="body w-100 p-3">
                <img
                  id="preview_img"
                  :src="body_content"
                  @error="$event.target.src = body_content"
                  class="w-100"
                  alt="body-content"
                />
                <div class="message">What Recepient See</div>
              </div>
              <div
                class="d-flex justify-content-start website w-100 px-3 py-1"
                v-if="preview_website"
              >
                <div>
                  <img
                    id="preview_img"
                    :src="preview_website"
                    @error="$event.target.src = preview_website"
                    width="16"
                    height="16"
                    alt="body-content"
                  />
                </div>
                <div>
                  <img
                    id="preview_img"
                    :src="social_content"
                    @error="$event.target.src = social_content"
                    width="100"
                    height="7"
                    alt="body-content"
                  />
                </div>
              </div>
              <div
                class="d-flex justify-content-start website w-100 px-3 py-1"
                v-if="preview_email"
              >
                <div>
                  <img
                    id="preview_img"
                    :src="preview_email"
                    @error="$event.target.src = preview_email"
                    width="16"
                    height="16"
                    alt="body-content"
                  />
                </div>
                <div>
                  <img
                    id="preview_img"
                    :src="social_content"
                    @error="$event.target.src = social_content"
                    width="100"
                    height="7"
                    alt="body-content"
                  />
                </div>
              </div>
              <div
                class="d-flex justify-content-start website w-100 px-3 py-1"
                v-if="preview_phone"
              >
                <div>
                  <img
                    id="preview_img"
                    :src="preview_phone"
                    @error="$event.target.src = preview_phone"
                    width="16"
                    height="16"
                    alt="body-content"
                  />
                </div>
                <div>
                  <img
                    id="preview_img"
                    :src="social_content"
                    @error="$event.target.src = social_content"
                    width="100"
                    height="7"
                    alt="body-content"
                  />
                </div>
              </div>
              <div
                class="d-flex justify-content-start website w-100 px-3 py-1"
                v-if="preview_linked_in"
              >
                <div>
                  <img
                    id="preview_img"
                    :src="preview_linked_in"
                    @error="$event.target.src = preview_linked_in"
                    width="16"
                    height="16"
                    alt="body-content"
                  />
                </div>
                <div>
                  <img
                    id="preview_img"
                    :src="social_content"
                    @error="$event.target.src = social_content"
                    width="100"
                    height="7"
                    alt="body-content"
                  />
                </div>
              </div>
              <div
                class="text-center mt-2 w-100"
                :class="{
                  hightlight: isFooter,
                }"
              >
                <div
                  v-if="false"
                  class="switch-icon rounded-circle cursor-pointer"
                  v-c-tooltip="{
                    content: 'View footer cropped image',
                    placement: 'top',
                  }"
                  @click="isShowCropped = !isShowCropped"
                >
                  <i
                    class="fa-solid fa-eye-slash close-eye"
                    v-if="isShowCropped"
                  ></i>
                  <i class="fa-solid fa-eye eye" v-else></i>
                </div>
                <img
                  v-if="isShowCropped"
                  id="preview_img"
                  :src="preview_footer_cropped"
                  class="w-100"
                  @error="$event.target.src = footer_placeholder"
                  alt="footer"
                />
                <img
                  v-else
                  id="preview_img"
                  :src="preview_footer"
                  class="w-100"
                  @error="$event.target.src = footer_placeholder"
                  alt="footer"
                />
              </div>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :aspectRatio="0"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.file"
      @modalCallBack="cropperModalCallBack"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import Select from "@/components/reusable/Fields/Select.vue";
import CropperModal from "@/components/reusable/CropperModal";

import body_content from "../../../../public/img/email-content.svg";
import social_content from "../../../../public/img/email-line.svg";
import linked_in_placeholder from "../../../../public/img/email_template/uemae/linked_in.png";
import header_placeholder from "../../../../public/img/placeholder-header.png";
import footer_placeholder from "../../../../public/img/placeholder-footer.png";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  name: "EmailAssets",
  components: {
    Select,
    CropperModal,
  },
  data() {
    return {
      header_placeholder,
      body_content,
      social_content,
      footer_placeholder,
      linked_in_placeholder,
      orgs: {},
      email_static_assets: {},
      payload: {},
      temp: {},
      fileType: "image/png",
      isSubmitting: false,
      isShowCropped: false,
      cropperModal: {
        isShowPopup: false,
        file: null,
        buttonLabel: "Apply",
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      "getEmailStaticAssestsEnum",
      "getEmailStaticAssests",
      "allCustomerOrganisation",
    ]),
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
    emailStaticAssetsOpt() {
      return this.getEmailStaticAssestsEnum;
    },
    options() {
      return {
        organisation: this.allOrganisationListOpt,
        email_static_assets: this.getEmailStaticAssestsEnum,
      };
    },
    preview_header() {
      if (this.payload["header_image"]) {
        return URL.createObjectURL(this.payload["header_image"]);
      } else if (this.getEmailStaticAssests["header_image"]) {
        return `${
          this.getEmailStaticAssests["header_image"]
        }?${new Date().getTime()}`;
      }
      return this.header_placeholder;
    },
    preview_footer() {
      if (this.payload["footer_image"]) {
        return URL.createObjectURL(this.payload["footer_image"]);
      } else if (this.getEmailStaticAssests["footer_image"]) {
        return `${
          this.getEmailStaticAssests["footer_image"]
        }?${new Date().getTime()}`;
      }
      return this.footer_placeholder;
    },
    preview_footer_cropped() {
      if (this.payload["footer_cropped_image"]) {
        return URL.createObjectURL(this.payload["footer_cropped_image"]);
      } else if (this.getEmailStaticAssests["footer_cropped_image"]) {
        return `${
          this.getEmailStaticAssests["footer_cropped_image"]
        }?${new Date().getTime()}`;
      }
      return this.footer_placeholder;
    },
    preview_website() {
      if (this.payload["website_icon"]) {
        return URL.createObjectURL(this.payload["website_icon"]);
      } else if (this.getEmailStaticAssests["website_icon"]) {
        return `${
          this.getEmailStaticAssests["website_icon"]
        }?${new Date().getTime()}`;
      }
      return false;
    },
    preview_email() {
      if (this.payload["email_icon"]) {
        return URL.createObjectURL(this.payload["email_icon"]);
      } else if (this.getEmailStaticAssests["email_icon"]) {
        return `${
          this.getEmailStaticAssests["email_icon"]
        }?${new Date().getTime()}`;
      }
      return false;
    },
    preview_phone() {
      if (this.payload["phone_icon"]) {
        return URL.createObjectURL(this.payload["phone_icon"]);
      } else if (this.getEmailStaticAssests["phone_icon"]) {
        return `${
          this.getEmailStaticAssests["phone_icon"]
        }?${new Date().getTime()}`;
      }
      return false;
    },
    preview_linked_in() {
      if (this.payload["linkedin_icon"]) {
        return URL.createObjectURL(this.payload["linkedin_icon"]);
      } else if (this.getEmailStaticAssests["linkedin_icon"]) {
        return `${
          this.getEmailStaticAssests["linkedin_icon"]
        }?${new Date().getTime()}`;
      }
      return false;
    },
    checkFileUpload() {
      if (this.email_static_assets.assets_type) {
        if (this.payload[this.email_static_assets.assets_type.code]) {
          return true;
        }
      }
      return false;
    },
    checkFileExist() {
      if (this.email_static_assets.assets_type) {
        if (
          this.getEmailStaticAssests[this.email_static_assets.assets_type.code]
        ) {
          return true;
        }
      }
      return false;
    },
    checkBlobExist() {
      if (this.email_static_assets.assets_type) {
        if (
          this.getEmailStaticAssests[
            this.email_static_assets.assets_type.code
          ] ||
          this.payload[this.email_static_assets.assets_type.code]
        ) {
          return false;
        }
      }
      return true;
    },
    getFileName() {
      if (this.email_static_assets.assets_type) {
        if (
          this.getEmailStaticAssests[this.email_static_assets.assets_type.code]
        ) {
          return this.getEmailStaticAssests[
            this.email_static_assets.assets_type.code
          ].split("images/")[1];
        } else if (this.payload[this.email_static_assets.assets_type.code]) {
          return this.payload[this.email_static_assets.assets_type.code].name;
        }
      }
      return false;
    },
    getPixelSize() {
      if (this.email_static_assets.assets_type) {
        if (this.email_static_assets.assets_type.code == "header_image") {
          return "940 x 400";
        } else if (
          this.email_static_assets.assets_type.code == "footer_image"
        ) {
          return "940 x 200";
        }
      }
    },
    isHeader() {
      if (this.email_static_assets.assets_type) {
        if (this.email_static_assets.assets_type.code == "header_image") {
          return true;
        }
      }
      return false;
    },
    isFooter() {
      if (this.email_static_assets.assets_type) {
        if (
          this.email_static_assets.assets_type.code == "footer_image" ||
          this.email_static_assets.assets_type.code == "footer_cropped_image"
        ) {
          return true;
        }
      }
      return false;
    },
    checkChildOrg() {
      return this.options["organisation"]?.length == 1 ? true : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchEmailStaticAssets",
      "fetchAllCustomerOrganisation",
      "fetchEmailStaticAssetsEnum",
      "uploadEmailStaticAssets",
      "deleteEmailStaticAssets",
    ]),
    handleChangeOrgSelect(name, value) {
      Vue.set(this.orgs, name, value);
      this.fetchEmailStaticAssets(value.path_acronym).then((res) => {
        if (this.getEmailStaticAssestsEnum.length) {
          this.handleChangeEmailSelect(
            "assets_type",
            this.getEmailStaticAssestsEnum[0]
          );
        }
      });
      this.payload = {};
    },
    handleChangeEmailSelect(name, value) {
      Vue.set(this.email_static_assets, name, value);
    },
    handleFile(type, event) {
      const file = event.target.files[0];
      const fileExt = this.getExtension(file.name);
      if (this.fileType.includes(fileExt)) {
        if (file.type.startsWith("image/")) {
          Vue.set(this.payload, type.code, file);
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "File extension not allowed !",
        });
      }
      this.$refs.fileref.value = null;
    },
    async onSubmit() {
      const isValid = await this.$refs.uploadform.validate();
      const file = Object.keys(this.payload);
      if (!isValid || file.length < 1) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            file.length < 1
              ? "please upload your image !"
              : "please fill mandatory fields!",
        });
        return;
      }
      this.isSubmitting = true;
      let finalPayload = {
        ...this.payload,
        acronym: this.orgs.organisation.path_acronym,
      };
      this.uploadEmailStaticAssets(finalPayload).then((res) => {
        this.fetchEmailStaticAssets(finalPayload.acronym).then((res) => {
          if (this.getEmailStaticAssestsEnum.length) {
            this.handleChangeEmailSelect(
              "assets_type",
              this.getEmailStaticAssestsEnum[0]
            );
          }
          this.payload = {};
          this.isSubmitting = false;
        });
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return _.lowerCase(parts[parts.length - 1]);
    },
    deleteFile(type) {
      if (this.payload[type.code]) {
        this.payload[type.code] = null;
      } else {
        let payload = {
          acronym: this.orgs.organisation.path_acronym,
          query: `delete_${type.code}=true`,
        };
        this.deleteEmailStaticAssets(payload).then((res) => {
          this.getEmailStaticAssests[type.code] = null;
        });
      }
    },
    openCropModal() {
      this.cropperModal.isShowPopup = true;
      this.cropperModal.file =
        this.payload[this.email_static_assets.assets_type.code];
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
      } else {
        this.cropperModal.isShowPopup = false;
        this.payload[this.email_static_assets.assets_type.code] = data.fileBlob;
      }
    },
    resetOption() {
      this.payload = {};
    },
  },
  mounted() {
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        this.handleChangeOrgSelect(
          "organisation",
          this.allOrganisationListOpt[0]
        );
      }
    });
    this.fetchEmailStaticAssetsEnum().then((res) => {
      if (this.getEmailStaticAssestsEnum.length) {
        this.handleChangeEmailSelect(
          "assets_type",
          this.getEmailStaticAssestsEnum[0]
        );
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}

.hightlight {
  border: 1px solid;
  border-color: #ff6565 !important;
}

.switch-icon {
  background-color: #696969;
  color: white;
  position: absolute;
  bottom: 120px;
  right: 12px;
}

.eye {
  padding: 8px 7px;
}

.close-eye {
  padding: 8px 6px;
}

.message {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  position: absolute;
  width: 240px;
  text-align: center;
  left: calc(50% - 120px);
  top: calc(50% - 0px);
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  color: #aaa;
  letter-spacing: 2px;
}
</style>
