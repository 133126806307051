<template>
  <div>
    <LoadingOverlay v-if="!isFetchingItem" />
    <CCard>
      <CCardBody class="m-4">
        <CRow class="justify-content-between">
          <CCol md="12" sm="12" lg="4">
            <ValidationObserver ref="uploadform" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)" name="uploadform">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder">
                      Organisation
                    </label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation"
                        :value="orgs.organisation"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        :disabled="checkChildOrg"
                        @input="handleChangeOrgSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder required">
                      From Email
                    </label>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="from_email_id"
                        :value="email_config.from_email_id"
                        @input="handleChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder required">
                      From Name
                    </label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="from_email_name"
                        :value="email_config.from_email_name"
                        @input="handleChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder">
                      Reply To
                      <em
                        class="
                          fa-solid fa-circle-info
                          ml-2
                          text-primary
                          cursor-pointer
                        "
                        v-c-tooltip="
                          'Please type comma to separate multiple email ids!'
                        "
                      ></em
                    ></label>
                    <ValidationProvider rules="emails" v-slot="{ errors }">
                      <Select
                        class="multiselect"
                        :name="'reply_to_email_id'"
                        :placeholder="''"
                        :value="email_config.reply_to_email_id"
                        @input="handleChange"
                        :taggable="true"
                        :multiple="true"
                        :clearable="true"
                        :noDrop="true"
                        :mapKeydown="handlers"
                        :selectOnTab="true"
                        :clearSearchOnSelect="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-5">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="font-weight-bolder">
                      CC
                      <em
                        class="
                          fa-solid fa-circle-info
                          ml-2
                          text-primary
                          cursor-pointer
                        "
                        v-c-tooltip="
                          'Please type comma to separate multiple email ids!'
                        "
                      ></em
                    ></label>
                    <ValidationProvider rules="emails" v-slot="{ errors }">
                      <Select
                        class="multiselect"
                        :name="'default_cc_email_ids'"
                        :placeholder="''"
                        :value="email_config.default_cc_email_ids"
                        @input="handleChange"
                        :taggable="true"
                        :multiple="true"
                        :clearable="true"
                        :noDrop="true"
                        :mapKeydown="handlers"
                        :selectOnTab="true"
                        :clearSearchOnSelect="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div
                    class="
                      d-flex
                      justify-content-end
                      col-lg-12 col-md-12 col-sm-12
                    "
                  >
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      class="mx-1"
                      @click="resetOption()"
                      :disabled="isSubmitting"
                      >Cancel</CButton
                    >
                    <CButton
                      type="button"
                      color="primary"
                      class="mx-1"
                      @click="onSubmit"
                      :disabled="isSubmitting"
                      >Submit</CButton
                    >
                  </div>
                </CRow>
              </form>
            </ValidationObserver>
          </CCol>
          <CCol md="12" sm="12" lg="7" class="border rounded preview-box">
            <CRow class="row justify-content-center m-0 mb-3">
              <CCol md="12" sm="12" lg="12">
                <CRow class="row p-3">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      border-bottom
                      pb-3
                      w-100
                    "
                  >
                    <div class="avatar">
                      <img
                        id="customer_logo_url"
                        :src="user_icon"
                        @error="$event.target.src = user_icon"
                        style="width: 100%; height: auto"
                        alt="logo"
                      />
                    </div>
                    <div>
                      <div class="d-flex ml-4">
                        <span class="from-email">
                          {{ email_config.from_email_name }}</span
                        >
                        <span class="from-address ml-1">
                          {{
                            left_arrow +
                            email_config.from_email_id +
                            right_arrow
                          }}
                        </span>
                      </div>
                      <div class="d-flex ml-4">
                        <span class="to-name">to me</span>
                        <span class="arrow-down"
                          ><i
                            class="
                              fa-solid fa-caret-down
                              border
                              rounded
                              px-1
                              ml-2
                            "
                            @click="isShowToMe = !isShowToMe"
                          ></i
                        ></span>
                        <div class="to-me" v-if="isShowToMe">
                          <div
                            class="modal d-block"
                            @click="isShowToMe = !isShowToMe"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content border rounded _2HQv3">
                                <div class="modal-body">
                                  <table class="m-0">
                                    <tbody>
                                      <tr>
                                        <td class="me-label">
                                          <span>from:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="from-email">
                                            {{
                                              email_config.from_email_name
                                            }}</span
                                          >
                                          <span class="from-address ml-1">
                                            {{
                                              left_arrow +
                                              email_config.from_email_id +
                                              right_arrow
                                            }}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>to:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="to-email"
                                            >xxxxxxxxxxxxxx@mail.com</span
                                          >
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>reply-to:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="from-address">{{
                                            email_config.reply_to_email_id.join(
                                              ","
                                            )
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>cc:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="from-address">{{
                                            email_config.default_cc_email_ids
                                          }}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>date:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="date"
                                            >24 Apr 2022, 12:08</span
                                          >
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>subject:</span>
                                        </td>
                                        <td class="me-value">
                                          <span>xxxxxxxxxxxxxx</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="me-label">
                                          <span>mailed-by:</span>
                                        </td>
                                        <td class="me-value">
                                          <span class="from-address">{{
                                            email_config.from_email_id.split(
                                              "@"
                                            )[1]
                                          }}</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="body w-100 p-3">
                    <img
                      id="preview_img"
                      :src="body_content"
                      @error="$event.target.src = body_content"
                      class="w-100"
                      alt="body-content"
                    />
                    <div class="message">Content received by recipient</div>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });

extend("emails", (val) => {
  const rex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const res =
    val && Array.isArray(val) && val.some((email) => !rex.test(email));
  return res ? "Invalid Email" : true;
});

import user_icon from "../../../../public/img/email_avatar.svg";
import body_content from "../../../../public/img/recepient-content.svg";
export default {
  name: "EmailConfig",
  components: {
    Select,
    TextInput,
  },
  data() {
    return {
      user_icon,
      body_content,
      orgs: {},
      email_config: {},
      payload: {},
      isSubmitting: false,
      left_arrow: "<",
      right_arrow: ">",
      isShowToMe: false,
      default_settings: {
        from_email_name: "TalentFind System",
        from_email_id: "info@ohrrecruitment.com",
        reply_to_email_id: "",
        default_cc_email_ids: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrgIDFromAccessToken",
      "getCustomerId",
      "allCustomerOrganisation",
      "getEmailConfiguration",
    ]),
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
    options() {
      return {
        organisation: this.allOrganisationListOpt,
      };
    },
    isFetchingItem() {
      return this.allOrganisationListOpt?.length;
    },
    checkChildOrg() {
      return this.options["organisation"]?.length == 1 ? true : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchAllCustomerOrganisation",
      "postEmailConfiguration",
      "fetchEmailConfiguration",
      "updateEmailConfiguration",
    ]),

    handleChangeOrgSelect(name, value) {
      this.fetchEmailConfiguration(value?.path_acronym).then((res) => {
        this.payload = {};
        this.email_config = {};
        if (this.getEmailConfiguration?.acronym) {
          _.forOwn(this.default_settings, (value, key) => {
            let resValue =
              ["reply_to_email_id", "default_cc_email_ids"].includes(key) &&
              this.getEmailConfiguration[key]
                ? [...this.getEmailConfiguration[key]?.split(",")]
                : this.getEmailConfiguration[key];
            if (resValue) this.email_config[key] = resValue;
          });
        } else {
          _.forOwn(this.default_settings, (value, key) => {
            if (value) this.email_config[key] = value;
          });
        }
      });
      Vue.set(this.orgs, name, value);
    },
    handleChange(name, value) {
      Vue.set(this.email_config, name, value);
      Vue.set(
        this.payload,
        name,
        ["reply_to_email_id", "default_cc_email_ids"].includes(name)
          ? value.join(",")
          : value
      );
    },
    async onSubmit() {
      const isValid = await this.$refs.uploadform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.isSubmitting = true;

      let finalPayload = {
        ...this.payload,
        from_email_name:
          this.payload?.from_email_name ||
          this.getEmailConfiguration?.from_email_name ||
          this.default_settings.from_email_name,
        from_email_id:
          this.payload?.from_email_id ||
          this.getEmailConfiguration?.from_email_id ||
          this.default_settings.from_email_id,
        default_template: this.getEmailConfiguration?.default_template
          ? this.getEmailConfiguration?.default_template
          : false,
        acronym: this.orgs?.organisation?.path_acronym,
        customer_uid: this.orgs?.organisation?.customer_id,
        org_id: this.orgs?.organisation?.code,
      };
      if (this.getEmailConfiguration?.acronym) {
        this.updateEmailConfiguration({
          config_id: this.getEmailConfiguration?.email_config_id,
          payload: finalPayload,
        }).then((res) => {
          this.payload = {};
          this.isSubmitting = false;
        });
      } else {
        this.postEmailConfiguration(finalPayload).then((res) => {
          this.payload = {};
          this.isSubmitting = false;
        });
      }
    },
    handlers: (map, vm) => ({
      ...map,
      188: (e) => {
        e.preventDefault();
        vm.$nextTick(() => vm.$refs.search.focus());
        return vm.typeAheadSelect();
      },
    }),
    resetOption() {
      this.handleChangeOrgSelect("organisation", this.orgs.organisation);
    },
  },
  mounted() {
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        this.handleChangeOrgSelect(
          "organisation",
          this.allOrganisationListOpt[0]
        );
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}

.from-email {
  font-size: 14px;
  font-weight: 700;
}

.from-address,
.date,
.to-email {
  color: #555;
  font-size: 13px;
}

.to-name,
.arrow-down {
  font-size: 13px;
  color: #acacac;
}

.message {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  position: absolute;
  width: 240px;
  text-align: center;
  left: calc(50% - 120px);
  top: calc(50% - -30px);
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  color: #aaa;
  letter-spacing: 2px;
}

.to-me {
  .modal {
    position: absolute !important;
    top: 35px;
    left: 85px;
  }

  ._2HQv3 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.055);
    padding: 5px;
  }

  .modal-body {
    padding: 8px !important;
  }

  .me-label {
    color: #999;
    padding: 2px 16px 2px 0;
    text-align: right;
    white-space: nowrap;
    vertical-align: top;
    width: 0;
  }
}
</style>

<style lang="scss">
.multiselect {
  .vs__actions {
    display: none !important;
  }

  .vs__selected {
    background-color: #fafafa !important;
    border-color: #ebe2e2 !important;
  }
}
</style>
