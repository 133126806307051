<template>
  <div>
    <CCard>
      <CCardBody class="m-4">
        <CRow class="row" v-if="isConfigExist">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="d-flex justify-content-center _0Gn3 rounded">
              <div class="modal show d-block">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content border-0 _2HQv3">
                    <div class="modal-body" v-if="isDefaultTemplate">
                      <span>
                        Please click here to Enable auto email transaction
                        template!</span
                      >
                      <div class="pt-4 d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn btn-primary mx-1"
                          data-dismiss="modal"
                          @click="uploadAllTemplate()"
                        >
                          Enable
                        </button>
                      </div>
                    </div>
                    <div class="modal-body" v-else>
                      <div class="text-center">
                        <span>
                          Please update the email config once; <br />
                          to enable the email templates !</span
                        >
                      </div>
                      <div class="mt-3 text-center">
                        <button
                          type="button"
                          class="btn btn-outline-primary mx-1"
                          data-dismiss="modal"
                          @click="navigateToConfig()"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CRow>
        <CRow class="justify-content-between" v-else>
          <CCol md="12" sm="12" lg="4">
            <ValidationObserver ref="uploadform" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)" name="uploadform">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder">
                      Organisation
                    </label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation"
                        :value="orgs.organisation"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        :disabled="checkChildOrg"
                        @input="handleChangeOrgSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-4">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder">Category</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="template_type"
                        :value="email_static_template.template_type"
                        :options="
                          options && options['email_static_template']
                            ? options['email_static_template']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeEmailSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select mr-1"
                      v-c-tooltip="
                        `Don't delete any system variable or paths. if doubtful, please reset that particular selection !`
                      "
                    />
                  </div>
                </CRow>
              </form>
            </ValidationObserver>
          </CCol>
          <CCol md="12" sm="12" lg="7" class="border rounded preview-box">
            <CRow class="row justify-content-center m-0 mb-3 border">
              <div>
                <div class="header w-100">
                  <img
                    id="preview_img"
                    :src="preview_header"
                    @error="$event.target.src = header_placeholder"
                    class="w-100"
                    alt="header"
                  />
                </div>
                <div class="body w-100 px-3">
                  <div v-for="(item, index) in getSectionItem" :key="index">
                    <CRow class="p-3 bg-white section">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div
                          class="d-flex w-100"
                          :class="
                              ['notify', 'button', 'head'].includes(item.type) 
                              ? 'justify-content-center' 
                              : 'justify-content-between',
                          "
                        >
                          <div>
                            <p
                              class="my-1"
                              :style="{
                                'font-size':
                                  item.type == 'notify' ? '9px' : '10px',
                              }"
                              v-html="item.content"
                            ></p>
                          </div>
                          <div
                            class="d-flex"
                            v-if="['text', 'head'].includes(item.type)"
                          >
                            <!-- <input
                              v-if="item.type == 'button'"
                              type="color"
                              id="head"
                              name="head"
                              :value="textEditor.color"
                            /> -->
                            <em
                              v-c-tooltip="'Reset'"
                              class="fas fa-sync-alt cursor-pointer edit mr-2"
                              @click="resetSection(item)"
                            >
                            </em>
                            <em
                              v-c-tooltip="'Edit'"
                              class="fas fa-edit cursor-pointer edit"
                              @click="showEditModal(item)"
                            >
                            </em>
                          </div>
                        </div>
                      </div>
                    </CRow>
                  </div>
                </div>
                <div class="text-center w-100">
                  <img
                    id="preview_img"
                    :src="preview_footer"
                    class="w-100"
                    @error="$event.target.src = footer_placeholder"
                    alt="footer"
                  />
                </div>
              </div>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal
      class="text-black"
      :show.sync="textEditor.isShowPopup"
      :close-on-backdrop="false"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title white">Edit Section</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          :disabled="isSubmitting"
          @click="onSubmit"
          >Confirm</CButton
        >
      </template>
      <div class="col-lg-12 col-md-12 col-sm-12 p-0">
        <RichTextEditor
          :name="textEditor.name"
          :value="textEditor.description"
          @change="handleInput"
        />
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import Select from "@/components/reusable/Fields/Select.vue";
import RichTextEditor from "@/components/reusable/Fields/RichTextEditor";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

import header_placeholder from "../../../../public/img/placeholder-header.png";
import footer_placeholder from "../../../../public/img/placeholder-footer.png";

export default {
  name: "EmailTemplate",
  components: {
    Select,
    RichTextEditor,
  },
  data() {
    return {
      header_placeholder,
      footer_placeholder,
      orgs: {},
      email_static_template: {},
      payload: {},
      fileType: "text/html",
      isSubmitting: false,
      isShowModal: false,
      textEditor: {
        isShowPopup: false,
        name: null,
        description: null,
        color: "#e66465",
      },
      payloadTemp: [],
    };
  },
  watch: {
    "textEditor.color": function (newVal, oldVal) {
      console.log(newVal, oldVal);
    },
  },
  computed: {
    ...mapGetters([
      "getEmailStaticTemplateEnum",
      "getEmailStaticTemplate",
      "allCustomerOrganisation",
      "getEmailStaticTemplateContent",
      "getEmailStaticAssests",
      "getEmailTemplateItem",
      "getEmailConfiguration",
    ]),
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
    emailStaticTemplateOpt() {
      return this.getEmailStaticTemplateEnum;
    },
    options() {
      return {
        organisation: this.allOrganisationListOpt,
        email_static_template: this.emailStaticTemplateOpt,
      };
    },
    preview_header() {
      if (this.getEmailStaticAssests["header_image"]) {
        return `${
          this.getEmailStaticAssests["header_image"]
        }?${new Date().getTime()}`;
      }
      return this.header_placeholder;
    },
    preview_footer() {
      if (this.getEmailStaticAssests["footer_image"]) {
        return `${
          this.getEmailStaticAssests["footer_image"]
        }?${new Date().getTime()}`;
      }
      return this.footer_placeholder;
    },
    getSectionItem() {
      return this.payloadTemp;
    },
    isConfigExist() {
      return this.getEmailConfiguration?.default_template ? false : true;
    },
    isDefaultTemplate() {
      return this.getEmailConfiguration?.default_template == false
        ? true
        : false;
    },
    checkChildOrg() {
      return this.options["organisation"]?.length == 1 ? true : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "getStaticEmailTemplate",
      "fetchAllCustomerOrganisation",
      "fetchEmailStaticTemplateEnum",
      "uploadEmailStaticTemplate",
      "deleteEmailStaticTemplate",
      "fetchEmailStaticTemplateContent",
      "getEmailTemplateContent",
      "postEmailTemplateContent",
      "updateEmailConfiguration",
      "fetchEmailConfiguration",
      "fetchEmailStaticAssets",
    ]),
    handleInput(name, value) {
      this.payloadTemp.forEach((e, i) => {
        if (e.section == name) {
          Vue.set(this.payloadTemp[i], "content", value);
        }
      });
    },
    handleChangeOrgSelect(name, value) {
      Vue.set(this.orgs, name, value);
      this.fetchEmailStaticAssets(value?.path_acronym);
      this.fetchEmailConfiguration(value?.path_acronym);
      this.getStaticEmailTemplate(value?.path_acronym).then((res) => {
        if (this.getEmailStaticTemplateEnum.length) {
          this.handleChangeEmailSelect(
            "template_type",
            this.getEmailStaticTemplateEnum[0]
          );
        }
      });
      this.payload = {};
    },
    handleChangeEmailSelect(name, value) {
      Vue.set(this.email_static_template, name, value);
      this.getEmailTemplateContent({
        acronym: this.orgs?.organisation?.path_acronym,
        type: value.code,
        query: true,
      }).then((res) => {
        this.payloadTemp = this.getEmailTemplateItem;
      });
    },
    handleFile(type, event) {
      const file = event.target.files[0];
      const fileExt = this.getExtension(file.name);
      if (this.fileType.includes(fileExt)) {
        if (file.type.startsWith("text/")) {
          Vue.set(this.payload, type.code, file);
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "File extension not allowed !",
        });
      }
      this.$refs.fileref.value = null;
    },
    async onSubmit() {
      const isValid = await this.$refs.uploadform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }

      this.isSubmitting = true;
      let appendAction = [];
      let finalPayload = {};

      if (this.payload[this.email_static_template?.template_type?.code]) {
        finalPayload = {
          ...this.payload,
          acronym: this.orgs?.organisation?.path_acronym,
        };
        appendAction = [
          ...appendAction,
          this.uploadEmailStaticTemplate(finalPayload),
        ];
      } else {
        finalPayload = {
          items: this.payloadTemp,
          type: this.email_static_template?.template_type?.code,
          acronym: this.orgs?.organisation?.path_acronym,
        };
        appendAction = [
          ...appendAction,
          this.postEmailTemplateContent(finalPayload),
        ];
      }
      Promise.all([appendAction]).then((res) => {
        this.getEmailTemplateContent({
          acronym: this.orgs?.organisation?.path_acronym,
          type: this.email_static_template?.template_type?.code,
          query: true,
        }).then((res) => {
          this.payload = {};
          this.isSubmitting = false;
          this.textEditor.isShowPopup = false;
        });
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return _.lowerCase(parts[parts.length - 1]);
    },
    deleteFile(type) {
      if (this.payload[type.code]) {
        this.payload[type.code] = null;
      } else {
        let payload = {
          acronym: this.orgs.organisation.path_acronym,
          query: `delete_${type.code}=true`,
          type: this.email_static_template?.template_type?.code,
        };
        this.deleteEmailStaticTemplate(payload).then((res) => {
          this.getEmailStaticTemplate[type.code] = null;
        });
      }
    },
    showEditModal(item) {
      this.textEditor.isShowPopup = true;
      this.textEditor.description = item.content;
      this.textEditor.name = item.section;
    },
    modalCallBack() {
      this.textEditor.isShowPopup = false;
    },
    resetSection(item) {
      this.getEmailTemplateContent({
        acronym: this.orgs?.organisation?.path_acronym,
        type: this.email_static_template?.template_type?.code,
        query: false,
      }).then((res) => {
        if (Array.isArray(res)) {
          this.payloadTemp.forEach((e, i) => {
            if (e.section == item.section) {
              Vue.set(this.payloadTemp[i], "content", res[i].content);
            }
          });
        }
        this.onSubmit();
      });
    },
    async uploadAllTemplate() {
      for (let e of this.getEmailStaticTemplateEnum) {
        this.getEmailTemplateContent({
          acronym: this.orgs?.organisation?.path_acronym,
          type: e.code,
          query: false,
        }).then((res) => {
          if (Array.isArray(res)) {
            let finalPayload = {
              items: res,
              type: e.code,
              acronym: this.orgs?.organisation?.path_acronym,
            };
            this.postEmailTemplateContent(finalPayload);
          }
        });
        await setTimeout(2000);
      }

      this.getEmailConfiguration.default_template = true;
      this.updateEmailConfiguration({
        config_id: this.getEmailConfiguration?.email_config_id,
        payload: this.getEmailConfiguration,
      });
    },
    navigateToConfig() {
      this.$emit("templateModalCallBAck", "Email Config");
    },
  },
  mounted() {
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        this.handleChangeOrgSelect(
          "organisation",
          this.allOrganisationListOpt[0]
        );
      }
    });
    this.fetchEmailStaticTemplateEnum().then((res) => {
      if (this.getEmailStaticTemplateEnum.length) {
        this.handleChangeEmailSelect(
          "template_type",
          this.getEmailStaticTemplateEnum[0]
        );
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}

.hightlight {
  border: 1px solid;
  border-color: #ff6565 !important;
}

.switch-icon {
  background-color: #696969;
  color: white;
  position: absolute;
  bottom: 120px;
  right: 12px;
}

.section {
  // background-color: #ebebeb;
  border-bottom: 1px solid #d8dbe0;
}

.edit {
  position: relative;
  right: -15px;
  top: -8px;
  font-size: 12px;
  opacity: 0;
}

.bg-white:hover .edit {
  opacity: 1;
}

#head {
  position: relative;
  right: -25px;
  top: -15px;
  width: 19px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.eye {
  padding: 8px 7px;
}

.close-eye {
  padding: 8px 6px;
}

.modal {
  position: relative !important;
}

._0Gn3 {
  background-color: #f0f2f5;
  z-index: 99999 !important;
}

._2HQv3 {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}
</style>
